import { useState } from 'react'
import { FaBars, FaTimes, FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { BsFillPersonLinesFill } from 'react-icons/bs'
import { Link } from 'react-scroll'

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const handleClick = () => setNav(!nav);

    return (
        <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300'>
            <div>
                {/* <img src={Logo} alt='Logo Image' style={{ width: '200px' }} /> */}
                <h1 className='text-3xl font-bold'>Francesco Sapia</h1>
            </div>

            {/* menu */}
            <ul className='hidden md:flex items-center'>
                <li>
                    <Link to='home' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        Home
                    </Link>
                </li>
                <li>
                    <Link to='about' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        Chi Sono
                    </Link>
                </li>
                <li>
                    <Link to='skills' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        Skills
                    </Link>
                </li>
                <li>
                    <Link to='work' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        Progetti
                    </Link>
                </li>
                <li>
                    <Link to='contact' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        Contatti
                    </Link>
                </li>
                <li>
                    <a href="/cv.pdf" target="_blank" rel="noopener noreferrer">
                        <button className='text-white group border-2 px-5 py-2 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
                            Curriculum
                        </button>
                    </a>
                </li>
            </ul>

            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile menu */}
            <ul
                className={
                    !nav
                        ? 'hidden'
                        : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'
                }
            >
                <li className='py-6 text-4xl'>
                    <Link onClick={handleClick} to='home' smooth={true} duration={500}>
                        Home
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <Link onClick={handleClick} to='about' smooth={true} duration={500}>
                        Chi Sono
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
                        Skills
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <Link onClick={handleClick} to='work' smooth={true} duration={500}>
                        Progetti
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    {' '}
                    <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
                        Contatti
                    </Link>
                </li>
                <li className='py-6 text-4xl'>
                    <a href='/cv.pdf'>
                        Curriculum
                    </a>
                </li>
            </ul>

            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://www.linkedin.com/in/francescosapia/' target='__blank'
                        >
                            Linkedin <FaLinkedin size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='https://github.com/sapiaf' target='__blank'
                        >
                            Github <FaGithub size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='mailto:info@francescosapia.it' target='__blank'
                        >
                            Email <HiOutlineMail size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                        <a
                            className='flex justify-between items-center w-full text-gray-300'
                            href='/cv.pdf' target='__blank'
                        >
                            Curriculum <BsFillPersonLinesFill size={30} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Navbar;
