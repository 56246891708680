import React from 'react'

const Footer = () => {
    return (
        <footer className='w-full h-[80px] flex justify-between items-center px-4 bg-[#070A19] text-gray-300'>
            <div>
                {/* <img src={Logo} alt='Logo Image' style={{ width: '200px' }} /> */}
                <h1 className='font-bold'>Francesco Sapia</h1>
            </div>
            <ul className='flex items-center'>
                <li>
                    <a href="https://www.iubenda.com/privacy-policy/54642889" className='hover:text-pink-600 transition duration-300' title="Privacy Policy ">Privacy Policy</a>
                </li>
                <li>
                    <a href="https://www.iubenda.com/privacy-policy/54642889/cookie-policy" className='hover:text-pink-600 transition duration-300' title="Cookie Policy ">Cookie Policy</a>
                </li>
            </ul>
        </footer>
    )
}

export default Footer
