import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-scroll'


const Home = () => {
    return (
        <div name='home' className='w-full h-screen  bg-[#0a192f]'>

            {/* Container */}
            <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
                <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
                    FRANCESCO SAPIA
                </h1>
                <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
                    Junior Web Developer.
                </h2>
                <p className='text-[#8892b0] py-4 max-w-[700px]'>
                    Junior Web Developer appassionato di tecnologia e design, focalizzato sulla creazione di siti web e applicazioni innovative.
                    Problem solver creativo, costantemente alla ricerca di progetti stimolanti che mi permettano di crescere professionalmente e migliorare le mie competenze.
                </p>
                <div>
                    <Link to='contact' smooth={true} duration={500} className='hover:text-pink-600 transition duration-300'>
                        <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
                            Contatti
                            <span className='group-hover:rotate-90 duration-300'>
                                <HiArrowNarrowRight className='ml-3' />
                            </span>
                        </button>
                    </Link>
                </div>
            </div>


        </div>
    )
}

export default Home
