import React from 'react'
import CodeFluent from '../assets/projects/code-fluent.png'
import Generation from '../assets/projects/generation-library.png'
import InsideJava from '../assets/projects/inside-java.png'


const Work = () => {
    return (
        <div name='work' className='w-full md-h-screen  text-gray-300 bg-[#0a192f]'>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600 '>Progetti</p>
                </div>

                <div className='flex justify-center items-center flex-col md:flex-row py-8'>
                    <div className='w-full md:w-1/2'>
                        <img src={Generation} alt="Work" className='w-full rounded-lg' />
                    </div>
                    <div className='w-full md:w-1/2 md:px-8 py-4'>
                        <div className='text-left'>
                            <h3 className='text-2xl font-bold mb-4'>Generation Library</h3>
                            <p className='text-lg mb-4'>
                                Progetto conclusivo al corso Generation Italy. Sviluppo di un sito di e-commerce per la vendita di libri. Utilizza tecnologie quali HTML, CSS, Bootstrap, Spring e Thymeleaf. Presenta un'interfaccia accattivante e un'area amministrativa avanzata per la gestione dei contenuti.
                            </p>
                            <div className='flex flex-wrap gap-5 mb-4'>
                                <span className='tech-stack'>HTML</span>
                                <span className='tech-stack'>CSS</span>
                                <span className='tech-stack'>Spring MVC</span>
                                <span className='tech-stack'>Thymeleaf</span>
                            </div>
                            <div className='py-3'>
                                <a href='https://github.com/sapiaf/generation-libri' target='__blank' className='px-6 py-2 mr-4 bg-gray-200 border-2 text-gray-800 hover:bg-gray-300'>GitHub</a>
                                <a href='https://generation-library-production.up.railway.app/' target='__blank' className='px-6 py-2 border-2 text-white hover:bg-pink-600 hover:border-pink-600'>Live</a>
                            </div>
                        </div>
                    </div>
                </div>




                <div className='flex justify-center items-center flex-col-reverse md:flex-row py-8'>
                    <div className='w-full md:w-1/2 md:px-8 py-4'>
                        <div className='text-left'>
                            <h3 className='text-2xl font-bold mb-4'>Inside Java</h3>
                            <p className='text-lg mb-4'>
                                Blog personale dedicato al mondo del Java.
                                Il sito presenta un'area utente per visualizzare e commentare i post , e un'area admin per la gestione dei post, degli utenti e dei commenti, oltre a offrire una dashboard analytics dettagliata.
                            </p>
                            <div className='flex flex-wrap gap-5 mb-4'>
                                <span className='tech-stack'>HTML</span>
                                <span className='tech-stack'>CSS</span>
                                <span className='tech-stack'>JavaScript</span>
                                <span className='tech-stack'>Bootstrap</span>
                            </div>
                            <div className='py-3'>
                                <a href='https://github.com/sapiaf/inside-java-blog' target='__blank' className='px-6 py-2 mr-4 bg-gray-200 border-2 text-gray-800 hover:bg-gray-300'>GitHub</a>
                                <a href='https://inside-java-production.up.railway.app' target='__blank' className='px-6 py-2 border-2 text-white hover:bg-pink-600 hover:border-pink-600'>Live</a>
                            </div>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2'>
                        <img src={InsideJava} alt="Work" className='w-full rounded-lg' />
                    </div>
                </div>



                <div className='flex justify-center items-center flex-col md:flex-row py-8'>
                    <div className='w-full md:w-1/2'>
                        <div className='relative'>
                            <img src={CodeFluent} alt="Work" className='w-full rounded-lg' />
                            <span className='uppercase absolute top-1 right-1 text-xs font-bold text-gray-300 bg-zinc-900/95 py-2 px-4 rounded-lg'>In Arrivo</span>
                            <span className='absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-gray-900/70 rounded-lg'></span>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 md:px-8 py-4'>
                        <div className='text-left'>
                            <h3 className='text-2xl font-bold mb-4'>CodeFluent</h3>
                            <p className='text-lg mb-4'>
                                Questa applicazione consente agli utenti di creare, modificare ed eliminare flashcards e set di carte. Il sistema di studio implementato si basa sul principio della spaced repetition, simile a quello presente in Anki, ottimizzando il processo di apprendimento attraverso la ripetizione graduale e strategica delle carte.                            </p>
                            <div className='flex flex-wrap gap-5 mb-4'>
                                <span className='tech-stack'>HTML</span>
                                <span className='tech-stack'>CSS</span>
                                <span className='tech-stack'>JavaScript</span>
                                <span className='tech-stack'>Spring</span>
                            </div>
                            <div className='py-3'>
                                <a href='https://github.com/sapiaf/CodeFluent' target='__blank' className='px-6 py-2 mr-4 bg-gray-200 border-2 text-gray-800 hover:bg-gray-300'>GitHub</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div >
    )
}

export default Work
