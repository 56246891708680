import React from 'react'

const About = () => {
    return (
        <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
            <div className='flex flex-col justify-center items-center w-full h-full'>
                <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                    <div className='sm:text-right pb-8 pl-4'>
                        <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                            Chi Sono
                        </p>
                    </div>
                    <div></div>
                </div>
                <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                    <div className='sm:text-right text-4xl font-bold'>
                        <p>Ciao, sono Francesco!
                            Junior Full Stack Developer.
                        </p>
                    </div>
                    <div>
                        <p>
                            Appassionato di informatica e amante dell'arrampicata, sono uno sviluppatore web con competenze sia nel front-end che nel back-end.
                            Sono ancora agli inizi del mio percorso nel mondo della programmazione, ma ho già portato a termine diversi progetti personali.
                            Recentemente, ho completato con successo un corso di Full-Stack Web Developer, che mi ha consentito di approfondire le mie conoscenze e di acquisire nuove competenze.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
