import React from 'react'

const Contact = () => {
    return (
        <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
            <form method='POST' action="https://getform.io/f/3b53856d-76e6-4887-a9dd-53cf87013573" className='flex flex-col max-w-[600px] w-full'>
                <div className='pb-8'>
                    <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>Contatti</p>
                </div>
                <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Nome' name='name' required />
                <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='email' required />
                <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Messaggio' required></textarea>
                <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-8 py-3 my-8 mx-auto flex items-center'>Invia</button>
            </form>
        </div>
    )
}

export default Contact
